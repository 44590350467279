body,
html {
  margin: 0;
  padding: 0;
}

canvas {
  background-color: #486ade;
  display: block;
}
